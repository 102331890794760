export const symbolTable = {};
var a = symbolTable;
a.xa1=1;
a.xa0=1;
a.xa1=1;
a.xa4=1;
a.xa7=1;
a.xaa=1;
a.xae=1;
a.xaf=1;
a.xb0=1;
a.xb1=1;
a.xb6=1;
a.xb7=1;
a.xb8=1;
a.xba=1;
a.xbf=1;
a.xc0=1;
a.xc1=1;
a.xc2=1;
a.xc3=1;
a.xc4=1;
a.xc5=1;
a.xc6=1;
a.xc7=1;
a.xc8=1;
a.xc9=1;
a.xca=1;
a.xcb=1;
a.xcc=1;
a.xcd=1;
a.xce=1;
a.xcf=1;
a.xd0=1;
a.xd1=1;
a.xd2=1;
a.xd3=1;
a.xd4=1;
a.xd5=1;
a.xd6=1;
a.xd7=1;
a.xd8=1;
a.xd9=1;
a.xda=1;
a.xdb=1;
a.xdc=1;
a.xde=1;
a.xdf=1;
a.xe0=1;
a.xe1=1;
a.xe2=1;
a.xe3=1;
a.xe4=1;
a.xe5=1;
a.xe6=1;
a.xe7=1;
a.xe8=1;
a.xe9=1;
a.xea=1;
a.xeb=1;
a.xec=1;
a.xed=1;
a.xee=1;
a.xef=1;
a.xf0=1;
a.xf1=1;
a.xf2=1;
a.xf3=1;
a.xf4=1;
a.xf5=1;
a.xf6=1;
a.xf8=1;
a.xf9=1;
a.xfa=1;
a.xfb=1;
a.xfc=1;
a.xfe=1;
a.xff=1;
a.x14a=2;
a.x153=1;
a.x16b=2;
a.x192=1;
a.x28c=1;
a.x252=2;
a.x259=2;
a.x25a=2;
a.x25c=2;
a.x25d=2;
a.x26f=2;
a.x278=2;
a.x287=2;
a.x292=2;
a.x2a4=2;
a.x2a7=2;
a.x2cf=2;
a.x414=2;
a.x41f=2;
a.x430=2;
a.x431=1;
a.x432=2;
a.x433=2;
a.x434=2;
a.x435=2;
a.x436=2;
a.x437=2;
a.x438=2;
a.x439=2;
a.x43a=1;
a.x43b=1;
a.x43c=2;
a.x43d=2;
a.x43e=1;
a.x43f=2;
a.x440=1;
a.x441=2;
a.x442=2;
a.x445=2;
a.x446=2;
a.x44b=2;
a.x44c=2;
a.x44f=2;

a.x1e3b=2;
a.x1e43=2;
a.x1e49=1;
a.x1ef2=3; //[4block](type 2?)
a.x1ef3=1;

a.x2010=1;
a.x2027=1;
a.x211e=1;
a.x2122=1;
a.x213b=2;
a.x2157=1;
a.x215c=2;
a.x21b2=1;
a.x21e6=1;
a.x21e8=1;
a.x21e9=1;
a.x2200=2;
a.x2202=1;
a.x222c=2;
a.x2237=2;
a.x2282=2;

a.x2401=3;//code - start
a.x2402=3;
a.x2403=3;
a.x2404=3;
a.x2405=3;
a.x2406=3;
a.x2407=3;
a.x2408=3;
a.x2409=3;
a.x240a=3;
a.x240b=3;
a.x240c=3;
a.x240d=3;
a.x240e=3;
a.x240f=3;
a.x2410=3;
a.x2411=3;
a.x2412=3;
a.x2413=3;
a.x2414=3;
a.x2415=3;
a.x2416=3;
a.x2417=3;
a.x2418=3;
a.x2419=3;
a.x241a=3;
a.x241b=3;
a.x241c=3;
a.x241d=3;
a.x241e=3;
a.x241f=3;
a.x2421=3;//code - end

a.x246a=2;
a.x246b=2;
a.x246c=2;
a.x246d=2;
a.x246e=2;
a.x246f=2;
a.x2470=2;
a.x2471=2;
a.x2472=2;
a.x2473=2;
a.x2503=2;
a.x2601=2;
a.x2602=2;
a.x260e=2;
a.x2611=2;
a.x261b=2;
a.x261c=2;
a.x261e=2;
a.x261f=2;
a.x2620=2;
a.x262f=2;
a.x2630=2;
a.x2631=2;
a.x2632=2;
a.x2633=2;
a.x2634=2;
a.x2635=2;
a.x2636=2;
a.x2637=2;
a.x2639=1;
a.x263a=2;
a.x263b=2;
a.x263c=2;
a.x263d=2;
a.x2660=2;
a.x2661=1;
a.x2662=1;
a.x2663=2;
a.x2664=1;
a.x2665=2;
a.x2666=1;
a.x2667=1;
a.x266a=1;
a.x266c=2;
a.x2702=2;
a.x2708=2;
a.x2709=2;
a.x2713=2;
a.x2715=2;
a.x2721=2;
a.x2729=2;
a.x274f=2;
a.x278e=2;
a.x278f=2;
a.x2790=2;
a.x2791=2;
a.x2792=2;
//
a.xe11b=3;
a.xe282=3;
a.xe568=3;
a.xe56b=3;
a.xe686=3;//[4block] - start
a.xe687=3;
a.xe7e0=3;
a.xe9cc=3;
a.xe9ce=3;
a.xe9cf=3;
a.xeb8b=3;
a.xec63=3;
a.xec64=3;
a.xec65=3;
a.xec66=3;
a.xec67=3;//[4block] - end

a.xec6c=1;
a.xec6a=1;

a.xec6e=3;//[4block] - start
a.xec71=3;
a.xec72=3;
a.xec73=3;
a.xec74=3;
a.xec71=3;
a.xec7c=3;
a.xec7d=3;
a.xec80=3;
a.xec83=3;
a.xec85=3;
a.xec86=3;
a.xec87=3;
a.xec88=3;
a.xec89=3;
a.xec8a=3;
a.xec8b=3;
a.xec8c=3;
a.xec8d=3;
a.xec8e=3;
a.xec8f=3;
a.xec90=3;
a.xec91=3;
a.xec9f=3;
a.xeca0=3;
a.xeca1=3;
a.xeca3=3;
a.xeca4=3;
a.xeca5=3;
a.xeca6=3;
a.xeca7=3;
a.xeca8=3;
a.xeca9=3;
a.xeca3=3;
a.xecae=3;
a.xecb5=3;
a.xecb6=3;
a.xecb8=3;
a.xecc1=3;
a.xecc2=3;
a.xecc8=3;
a.xecca=3;
a.xeccb=3;
a.xeccc=3;
a.xeccd=3;
a.xecce=3;
a.xed91=3;
a.xed92=3;
a.xee1e=3;
a.xee22=3;
a.xee58=3;
a.xee5a=3;
a.xee5c=3;
a.xee5e=3;
a.xee5f=3;
a.xeeb6=3;//[4block] - end

a.xf7e6=3;//[4block] - start
a.xf7e8=3;
a.xf7ee=3;
a.xf7ef=3;
a.xf7f0=3;
a.xf7f1=3;
a.xf7f2=3;
a.xf7f3=3;
a.xf7f4=3;
a.xf7f5=3;
a.xf7f6=3;
a.xf7f7=3;
a.xf7f8=3;
a.xf7f8=3;
a.xf7fa=3;//[4block] - end

a.xf849=3;//[4block] - start
a.xf84a=3;
a.xf84b=3;
a.xf84c=3;
a.xf84d=3;
a.xf84e=3;
a.xf84f=3;
a.xf850=3;
a.xf851=3;
a.xf852=3;
a.xf853=3;
a.xf854=3;
a.xf855=3;
a.xf856=3;
a.xf857=3;
a.xf858=3;
a.xf859=3;
a.xf85a=3;
a.xf85b=3;
a.xf85c=3;
a.xf85d=3;
a.xf85e=3;
a.xf85f=3;
a.xf860=3;
a.xf861=3;
a.xf862=3;
a.xf863=3;
a.xf864=3;
a.xf865=3;//[4block] - end

a.xff61=1;
a.xff62=1;
a.xff63=1;
a.xff64=1;
a.xff65=1;
a.xff66=1;
a.xff67=1;
a.xff68=1;
a.xff69=1;
a.xff6a=1;
a.xff6b=1;
a.xff6c=1;
a.xff6d=1;
a.xff6e=1;
a.xff6f=1;
a.xff70=1;
a.xff71=1;
a.xff72=1;
a.xff73=1;
a.xff74=1;
a.xff75=1;
a.xff76=1;
a.xff77=1;
a.xff78=1;
a.xff79=1;
a.xff7a=1;
a.xff7b=1;
a.xff7c=1;
a.xff7d=1;
a.xff7e=1;
a.xff7f=1;
a.xff80=1;
a.xff81=1;
a.xff82=1;
a.xff83=1;
a.xff84=1;
a.xff85=1;
a.xff86=1;
a.xff87=1;
a.xff88=1;
a.xff89=1;
a.xff8a=1;
a.xff8b=1;
a.xff8c=1;
a.xff8d=1;
a.xff8e=1;
a.xff8f=1;
a.xff90=1;
a.xff91=1;
a.xff92=1;
a.xff93=1;
a.xff94=1;
a.xff95=1;
a.xff96=1;
a.xff97=1;
a.xff98=1;
a.xff99=1;
a.xff9a=1;
a.xff9b=1;
a.xff9c=1;
a.xff9d=1;
a.xff9e=1;
a.xff9f=1;
a.xfffd=3;
